import {
  ActionIcon,
  Button,
  Card,
  Center,
  CloseButton,
  Col,
  Container,
  Grid,
  Image,
  Text,
  Textarea,
  ThemeIcon,
  useMantineTheme,
} from "@mantine/core";
import React, { Dispatch, MutableRefObject, SetStateAction } from "react";
import { SiAngellist, SiRocketdotchat } from "react-icons/si";
import { useParams } from "react-router-dom";
import { RemoteStateContext } from "../RemoteStateProvider";

export function ProposalCollection() {
  const remoteState = React.useContext(RemoteStateContext);
  const [proposal, setProposal] = React.useState("");
  const textAreaRef = React.useRef(null);

  const theme = useMantineTheme();
  const randomThemeColor = () =>
    Object.keys(theme.colors)[
      Math.floor(Math.random() * Object.keys(theme.colors).length)
    ];

  return (
    <>
      <Grid style={{ minWidth: "80%", margin: "0px" }}>
        <Col
          span={6}
          style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "2em" }}
        >
          <Center>
            <Image
              src={`${process.env.PUBLIC_URL}/wishlist.svg`}
              style={{ marginBottom: "2em" }}
            />
          </Center>
          <Center>
            <Textarea
              onChange={(e) => {
                setProposal(e.target.value);
              }}
              ref={textAreaRef}
              placeholder="Hier kannst du deinen Vorschlag eintragen"
              label="Dein Vorschlag"
              minRows={5}
              style={{ width: "100%" }}
            />
          </Center>
          <AddProposalButton
            addProposal={remoteState.addProposal}
            description={proposal}
            textAreaRef={textAreaRef}
            setProposal={setProposal}
          />
        </Col>
        <Col
          span={6}
          style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "2em" }}
        >
          {remoteState.proposals.length == 0 && (
            <Text style={{ fontSize: "2em" }}>
              Noch keine Vorschläge vorhanden.
            </Text>
          )}
          {remoteState.proposals.map((p, i) => (
            <Card key={i} style={{ marginTop: "1em", marginBottom: "1em" }}>
              <Grid>
                <Col span={1} style={{ margin: "auto" }}>
                  <ThemeIcon
                    variant="light"
                    size="lg"
                    color={randomThemeColor()}
                  >
                    <SiAngellist />
                  </ThemeIcon>
                </Col>
                <Col span={10}>
                  <Text weight={500} size="lg" key={i}>
                    {p.description}
                  </Text>
                </Col>
                {remoteState.currentUser.isModerator && (
                <Col span={1}>
                  <CloseButton
                    onClick={() => remoteState.deleteProposal(p.id)}
                  ></CloseButton>
                </Col>
                )}
              </Grid>
            </Card>
          ))}
        </Col>
        <Col span={12}>
          {remoteState.currentUser.isModerator && (
            <NextButton
              clickHandler={remoteState.startVoting}
              label="Widerstandabfrage starten"
            />
          )}
        </Col>
      </Grid>
    </>
  );
}

//@ts-ignore
function NextButton({ clickHandler, label }) {
  const { sessionId } = useParams();

  return (
    <Container>
      <Button
        variant="gradient"
        gradient={{ from: "teal", to: "blue", deg: 60 }}
        size="xl"
        style={{ marginTop: "3em" }}
        onClick={() => clickHandler(sessionId)}
      >
        {label}
      </Button>
    </Container>
  );
}

function AddProposalButton({
  addProposal,
  description: proposal,
  textAreaRef,
  setProposal,
}: {
  addProposal: (description: string) => Promise<void>;
  description: string;
  textAreaRef: MutableRefObject<null | HTMLTextAreaElement>;
  setProposal: Dispatch<SetStateAction<string>>;
}): JSX.Element {
  return (
    <Container>
      <Button
        size="xl"
        style={{ marginTop: "3em" }}
        onClick={() => {
          if (!proposal) {
            return;
          }
          addProposal(proposal);
          if (textAreaRef.current) {
            textAreaRef.current.value = "";
          }
          setProposal("");
        }}
      >
        Vorschlag abgeben
      </Button>
    </Container>
  );
}

import { Button, Center, Image, Text } from "@mantine/core";
import React from "react";
import { RemoteStateContext } from "../RemoteStateProvider";

export function PropagateProblemStatement() {
  const remoteState = React.useContext(RemoteStateContext);

  return (
    <Center style={{ flexDirection: "column", width: "40%", maxWidth: 500 }}>
      <Image src={`${process.env.PUBLIC_URL}/question.svg`} style={{ marginBottom: "2em" }} />
      <Text size="xl" weight="bold" style={{ marginBottom: "0.5em" }}>
        Entscheidungsbedarf verstehen
      </Text>
      <Text size="lg" style={{ marginBottom: "2em" }}>
        Der Spannungsgeber teilt der Gruppe mit, warum es wichtig ist für dieses
        Problem eine Entscheidung herbeizuführen. Was ist die übergeordnete Fragestellung die es zu lösen gilt?
      </Text>
      {remoteState.currentUser.isModerator && (
        <Button
          variant="gradient"
          gradient={{ from: "teal", to: "blue", deg: 60 }}
          size="xl"
          onClick={remoteState.startInformationRound}
        >
          {"Informationsrunde starten"}
        </Button>
      )}
    </Center>
  );
}

import {
  Button, Center,
  Col,
  Container,
  Grid,
  Text
} from "@mantine/core";
import React from "react";
import { useParams } from "react-router-dom";
import { UserCard } from "../components/UserCard";
import { RemoteStateContext } from "../RemoteStateProvider";

export function OpinionRound() {
  const remoteState = React.useContext(RemoteStateContext);
 
  return (
    <>
      <Grid style={{ margin: "0px", width: "40%", maxWidth: 500 }}>
        {remoteState.users.map((user, i) => (
          <Col span={3} key={i}>
            <Center>
              <UserCard user={user} key={i} />
            </Center>
          </Col>
        ))}
        <Col span={12}>
        <Text size="lg"> Jetzt teilt Eure subjektive Sichtweise auf die Spannung mit. Was ist Dir für eine zukünftige Lösung wichtig? Was ist deine persönliche Sichtweise auf die Spannung?
        </Text>
          <Center>
            {remoteState.currentUser.isModerator && (
              <NextButton
                clickHandler={remoteState.startProposalCollection}
                label="Vorschläge sammeln"
              />
            )}
          </Center>
        </Col>
      </Grid>
    </>
  );
}

//@ts-ignore
function NextButton({ clickHandler, label }) {
  const { sessionId } = useParams();

  return (
    <Container>
      <Button
        variant="gradient"
        gradient={{ from: "teal", to: "blue", deg: 60 }}
        size="xl"
        style={{ marginTop: "3em" }}
        onClick={() => clickHandler(sessionId)}
      >
        {label}
      </Button>
    </Container>
  );
}

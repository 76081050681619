import React, { ReactElement } from "react";
import { io } from "socket.io-client";

const socket =
  process.env.NODE_ENV === "production"
    ? io("https://api.decide.thilko.com", {transports: ["websocket"] })
    : io("ws://localhost:4000", {transports: ["websocket"] })

export interface User {
  id: string;
  name: string;
  avatarUrl: string;
  isModerator: boolean;
}

export interface Proposal {
  id: string;
  description: string;
}

export interface Result {
  id: string;
  description: string;
  sum: number;
  votes: UserVote[];
}
export interface UserVote {
  userId: string;
  rate: number;
}

export interface Vote {
  proposalId: string;
  rate: number;
}

export enum DecisionStep {
  START_SESSION,
  PROPAGATE_PROBLEM_STATEMENT,
  INFORMATION_ROUND,
  OPINION_ROUND,
  PROPOSAL_COLLECTION,
  VOTING,
  DECISION_MADE,
}

export interface RemoteState {
  startSession: (sessionId: string, userDetails: any) => Promise<void>;
  joinSession: (sessionId: string, userDetails: any) => Promise<void>;
  startInformationRound: () => Promise<void>;
  startOpinionRound: () => Promise<void>;
  startProposalCollection: () => Promise<void>;
  startVoting: () => Promise<void>;
  addProposal: (proposal: string) => Promise<void>;
  deleteProposal: (proposalId: string) => Promise<void>;
  addVotes: (votes: Vote[], userId: string) => Promise<void>;
  endVoting: () => Promise<void>;

  users: User[];
  currentUser: User;
  step: DecisionStep;
  isReady: Boolean;
  proposal: String;
  proposals: Proposal[];
  votes: Vote[];
  results: Result[];
}

const initialRemoteState = {
  startSession: (sessionId: string, user: any) => {
    socket.emit("event:start-session", {
      sessionId: sessionId,
      user: JSON.stringify(user),
    });
    return Promise.resolve();
  },
  joinSession: (sessionId: string, user: any) => {
    socket.emit("event:user-joined", {
      sessionId: sessionId,
      user: JSON.stringify(user),
    });
    return Promise.resolve();
  },
  startProposalCollection: () => {
    socket.emit("event:start-proposal-collection");
    return Promise.resolve();
  },
  startInformationRound: () => {
    socket.emit("event:start-information-round");
    return Promise.resolve();
  },
  startOpinionRound: () => {
    socket.emit("event:start-opinion-round");
    return Promise.resolve();
  },
  startVoting: () => {
    socket.emit("event:start-voting");
    return Promise.resolve();
  },
  endVoting: () => {
    socket.emit("event:end-voting");
    return Promise.resolve();
  },
  addProposal: (proposal: string) => {
    socket.emit("event:add-proposal", {
      description: proposal,
    });
    return Promise.resolve();
  },
  deleteProposal: (proposalId: string) => {
    socket.emit("event:delete-proposal", {
      proposalId: proposalId
    });
    return Promise.resolve();
  },
  addVotes: (votes: Vote[], userId: string) => {
    socket.emit("event:add-vote", {
      userId: userId,
      votes: votes,
    });
    return Promise.resolve();
  },
  users: [],
  currentUser: { id: "", name: "", avatarUrl: "", isModerator: false } as User,
  proposal: "",
  proposals: [],
  step: DecisionStep.START_SESSION,
  isReady: false,
  votes: [],
  results: [],
};

export const RemoteStateContext =
  React.createContext<RemoteState>(initialRemoteState);

export function RemoteStateProvider(props: { children: ReactElement }) {
  const [remoteState, setRemoteState] =
    React.useState<RemoteState>(initialRemoteState);

  React.useEffect(() => {
    socket.on("event:session-started", (users, user, session) => {
      setRemoteState((state) => ({
        ...state,
        users: JSON.parse(users),
        currentUser: JSON.parse(user),
        step: JSON.parse(session).step
      }));
    });
    socket.on("event:user-joined", (users, user, session) => {
      setRemoteState((state) => ({
        ...state,
        users: JSON.parse(users),
        currentUser: state.currentUser.id == "" ? JSON.parse(user) : state.currentUser,
        step: JSON.parse(session).step
      }));
    });    
    socket.on("event:decision-step-changed", (session) => {
      setRemoteState((state) => ({
        ...state,
        step: JSON.parse(session).step,
      }));
    });
    socket.on("event:proposals-updated", (proposals) => {
      setRemoteState((state) => ({
        ...state,
        proposals: JSON.parse(proposals),
      }));
    });
    socket.on("event:voting-ended", (votes) => {
      setRemoteState((state) => ({
        ...state,
        results: JSON.parse(votes),
      }));
    });
  }, []);

  return (
    <RemoteStateContext.Provider value={remoteState}>
      {props.children}
    </RemoteStateContext.Provider>
  );
}

import { MantineProvider, useMantineTheme } from "@mantine/core";
import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { v4 as uuid } from "uuid";
import "./App.css";
import { JoinSession } from "./pages/JoinSession";
import { RemoteStateProvider } from "./RemoteStateProvider";
import { StartSession } from "./pages/StartSession";

function App() {
  const theme = useMantineTheme();
  theme.colorScheme = "dark";

  return (
    <BrowserRouter>
      <MantineProvider theme={theme}>
        <RemoteStateProvider>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <StartSession />
                </Layout>
              }
            />
            <Route
              path="/:sessionId"
              element={
                <Layout>
                  <JoinSession />
                </Layout>
              }
            />
          </Routes>
        </RemoteStateProvider>
      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;

//@ts-ignore
function Layout({ children }) {
  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "rgb(26, 27, 30)",
      }}
    >
      {children}
    </div>
  );
}

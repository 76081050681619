import { Col, Grid, Stepper } from "@mantine/core";
import React from "react";
import { createStyles } from "@mantine/core";
import { DecisionStep, RemoteStateContext } from "../RemoteStateProvider";

export function Navigation() {
  const remoteState = React.useContext(RemoteStateContext);

  const mapping = new Map<DecisionStep, number>([
    [DecisionStep.PROPAGATE_PROBLEM_STATEMENT, 0],
    [DecisionStep.INFORMATION_ROUND, 1],
    [DecisionStep.OPINION_ROUND, 2],
    [DecisionStep.PROPOSAL_COLLECTION, 3],
    [DecisionStep.VOTING, 4],
    [DecisionStep.DECISION_MADE, 5],
  ]);

  const activeStep = mapping.get(remoteState.step) || 0;

  return (
    <Stepper active={activeStep} breakpoint="sm" style={{ maxWidth: "90%"}}>
      <Stepper.Step
        label="Entscheidungsbedarf verstehen"
        description="Warum ist diese Entscheidung wichtig?"
      ></Stepper.Step>
      <Stepper.Step
        label="Informationsrunde"
        description="Welche Informationen fehlen?"
      ></Stepper.Step>
      <Stepper.Step
        label="Meinungsrunde"
        description="Was denkst du über den Entscheidungsbedarf?"
      ></Stepper.Step>
      <Stepper.Step
        label="Vorschlagsrunde"
        description="Was ist dein Vorschlag?"
      ></Stepper.Step>
      <Stepper.Step
        label="Widerstandsabfrage"
        description="Wie hoch ist dein Widerstand?"
      ></Stepper.Step>
      <Stepper.Step label="Ergebnis" description=""></Stepper.Step>
    </Stepper>
  );
}

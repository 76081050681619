import React from "react";

export function useConfetti(): [boolean, number] {
    const CONFETTI_DURATION_MS = 4000;

    const [confetti, setConfetti] = React.useState(false);

    React.useEffect(() => {
        setConfetti(true);
        setTimeout(() => {
            setConfetti(false);
        }, CONFETTI_DURATION_MS - 1000);
    }, []);

    return [confetti, CONFETTI_DURATION_MS];
}
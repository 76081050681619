import {
  Button, Center,
  Container
} from "@mantine/core";
import React from "react";
import { Navigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { UserCard } from "../components/UserCard";
import { DecisionStep, RemoteStateContext } from "../RemoteStateProvider";

export function StartSession() {
  const remoteState = React.useContext(RemoteStateContext);
  const [userId, setUserId] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  
  React.useEffect(() => {
    setUserId(`${Math.floor(Math.random() * 10000)}`);
    setSessionId(uuid());
  }, []);

  const userDetails = {
    id: userId,
    isModerator: true,
    name: `Anonymus Decision Maker ${userId}`,
    avatarUrl: `https://i.pravatar.cc/300?u=${userId}`,
  };

  if (remoteState.step === DecisionStep.PROPAGATE_PROBLEM_STATEMENT) {
    return <Navigate to={`/${sessionId}`} />
   }

  return (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Center style={{ marginTop: "1em" }}>
        <JoinButton
          startSession={remoteState.startSession}
          userDetails={userDetails}
          sessionId={sessionId}
        />
      </Center>
    </div>
  );
}

//@ts-ignore
function JoinButton({startSession,userDetails,sessionId}) {
  return (
    <Container>
      <UserCard user={userDetails} />
      <Button
        variant="gradient"
        gradient={{ from: "teal", to: "blue", deg: 60 }}
        size="xl"
        style={{ marginTop: "3em" }}
        onClick={() => {
          startSession(sessionId, userDetails);
        }}
      >
        Join
      </Button>
    </Container>
  );
}


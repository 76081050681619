import {
  Button, Center, Container
} from "@mantine/core";
import React from "react";
import { useParams } from "react-router-dom";
import { DecisionMade } from "./DecisionMade";
import { InformationRound } from "./InformationRound";
import { Navigation } from "../components/Navigation";
import { OpinionRound } from "./OpinionRound";
import { PropagateProblemStatement } from "./PropagateProblemStatement";
import { ProposalCollection } from "./ProposalCollection";
import { DecisionStep, RemoteStateContext } from "../RemoteStateProvider";
import { UserCard } from "../components/UserCard";
import { Voting } from "./Voting";

export function JoinSession() {
  const remoteState = React.useContext(RemoteStateContext);
  const [userId, setUserId] = React.useState("");
  
  React.useEffect(() => {
    setUserId(`${Math.floor(Math.random() * 10000)}`);
  }, []);

  const userDetails = {
    id: userId,
    name: `Anonymus Decision Maker ${userId}`,
    avatarUrl: `https://i.pravatar.cc/300?u=${userId}`,
    isModerator: false
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {remoteState.users.length < 1 ? (
        <Center style={{ marginTop: "1em" }}>
          <JoinButton
            joinSession={remoteState.joinSession}
            userDetails={userDetails}
            isReady={remoteState.isReady}
          />
        </Center>
      ) : (
        <>
          <div
            style={{
              minWidth: "100%",
              marginTop: "1em",
              marginBottom: "1em",
              flex: "1",
            }}
          >
            <Center>
              <Navigation />
            </Center>
          </div>

          <div
            style={{
              flex: "9",
              minWidth: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {remoteState.step === DecisionStep.PROPAGATE_PROBLEM_STATEMENT && (
              <PropagateProblemStatement />
            )}

            {remoteState.step === DecisionStep.INFORMATION_ROUND && (
              <>
                <InformationRound />
              </>
            )}

            {remoteState.step === DecisionStep.OPINION_ROUND && (
              <>
                 <OpinionRound />               
              </>
            )}

            {remoteState.step === DecisionStep.PROPOSAL_COLLECTION && (
              <>
                <ProposalCollection />
              </>
            )}

            {remoteState.step === DecisionStep.VOTING && (
              <>
                <Voting />
              </>
            )}

            {remoteState.step === DecisionStep.DECISION_MADE && (
              <>
                <DecisionMade />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

//@ts-ignore
function JoinButton({ joinSession, userDetails, isReady }) {
  const { sessionId } = useParams();

  return (
    <Container>
      <UserCard user={userDetails} />
      <Button
        variant="gradient"
        gradient={{ from: "teal", to: "blue", deg: 60 }}
        size="xl"
        style={{ marginTop: "3em" }}
        onClick={() => joinSession(sessionId, userDetails)}
      >
        Join
      </Button>
    </Container>
  );
}
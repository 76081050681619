import {
  Card,
  Center,
  Col,
  Grid,
  Group,
  Image,
  List,
  Text,
  ThemeIcon,
} from "@mantine/core";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import React from "react";
import { MdDangerous, MdVerified } from "react-icons/md";
import { RemoteStateContext, Result } from "../RemoteStateProvider";
import { useConfetti } from "../useConfetti";

export function DecisionMade() {
  const remoteState = React.useContext(RemoteStateContext);
  const [showConfetti, confettiDuration] = useConfetti();

  const sortedResults = remoteState.results.sort((r1, r2) => r1.sum - r2.sum);

  const groupRefusal = (result: Result, numberOfUsers: number) => {
    return `${(result.sum / numberOfUsers).toFixed(2)}`;
  };

  return (
    <Grid style={{ margin: "0", maxWidth: "80%" }}>
      <Col span={12}>
        <Center style={{ maxWidth: "100%" }}>
          {showConfetti && <ConfettiExplosion duration={confettiDuration} />}
        </Center>
      </Col>
      <Col span={12} style={{ marginBottom: "3em" }}>
        <Center>
          <Card>
            <Grid style={{ margin: "0" }}>
              <Col span={2}>
                <Image src="/king.png" />
              </Col>
              <Col span={10}>
                <Center style={{ flexDirection: "column" }}>
                  <Text size="xl">{sortedResults[0]?.description}</Text>
                  <Group>
                    <ThemeIcon color="green" size="xl">
                      <MdVerified />
                    </ThemeIcon>
                    <Text size="sm">Gesamt:{sortedResults[0].sum}</Text>
                    <Text size="sm">
                      Durchschnitt{" "}
                      {groupRefusal(sortedResults[0], remoteState.users.length)}
                    </Text>
                  </Group>
                </Center>
              </Col>
            </Grid>
          </Card>
        </Center>
      </Col>
      <Col span={12}>
        <Center>
          <List
            icon={
              <ThemeIcon color="pink" size="md" style={{ marginRight: "8px" }}>
                <MdDangerous />
              </ThemeIcon>
            }
            type="unordered"
            size="xl"
          >
            {sortedResults.length > 1 && sortedResults.slice(1).map((result, index) => (
              <List.Item key={index} style={{ marginBottom: "1em" }}>
                <Text size="xl">{result.description}</Text>
                <Group>
                  <Text size="sm">(Gesamt: {result.sum}</Text>
                  <Text size="sm">
                    Durchschnitt:
                    {groupRefusal(
                      sortedResults[index],
                      remoteState.users.length
                    )})
                  </Text>
                </Group>
              </List.Item>
            ))}
          </List>
        </Center>
      </Col>
    </Grid>
  );
}

import {
    Badge, Card,
    CardSection, Group,
    Image,
    Text,
    useMantineTheme
} from "@mantine/core";
  
export function UserCard(props: { user: any }) {
    const theme = useMantineTheme();
    const { user } = props;
  
    return (
      <Card style={{ width: 250, minHeight: "100%" }}>
        <CardSection>
          <Image radius="sm" src={user.avatarUrl} />
        </CardSection>
        <Group
          position="apart"
          style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
        >
          <Text weight={700}>{user.name}</Text>
          {user.isModerator && (
            <Badge color="teal" variant="light">
              Moderator
            </Badge>
          )}
        </Group>
      </Card>
    );
  }
  
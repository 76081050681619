import {
  Button,
  Card,
  Col,
  Container,
  Grid,
  Slider,
  Text
} from "@mantine/core";
import React from "react";
import { RemoteStateContext, Vote } from "../RemoteStateProvider";

export function Voting() {
  const remoteState = React.useContext(RemoteStateContext);
  const [userId, setUserId] = React.useState("");
  const [userHasVoted, setUserHasVoted] = React.useState(false);
  const [votes, setVotes] = React.useState(new Array<Vote>());

  React.useEffect(() => {
    setUserId(`${Math.floor(Math.random() * 10000)}`);
  }, []);

  return (
    <Grid gutter="lg" style={{ margin: "0" }}>
      {remoteState.proposals.map((p, i) => (
        <Col span={6} key={i} style={{ padding: "2em" }}>
          <Card style={{ padding: "2em" }}>
            <Text weight={500} size="lg" key={i}>
              {p.description}
            </Text>

            <Slider
              defaultValue={5}
              key={p.id}
              onChange={(value: number) => {
                const allVotes = remoteState.votes;
                const vote: Vote = allVotes.find(
                  (v) => v.proposalId === p.id
                ) || { proposalId: "", rate: 0 };
                vote.proposalId = p.id;
                vote.rate = value;

                const index = allVotes.findIndex(
                  (vote) => vote.proposalId === p.id
                );
                if (index === -1) {
                  allVotes.push(vote);
                } else {
                  allVotes[index] = vote;
                }
                setVotes(allVotes);
              }}
              marks={[
                { value: 0, label: "OK" },
                { value: 1 },
                { value: 2 },
                { value: 3 },
                { value: 4 },
                { value: 5 },
                { value: 6 },
                { value: 6 },
                { value: 7 },
                { value: 8 },
                { value: 9 },
                { value: 10, label: "Dagegen" },
              ]}
              max={10}
              min={0}
            />
          </Card>
        </Col>
      ))}
      <Col span={12}>
        <AddVotesButton
          disabled={userHasVoted}
          clickHandler={() => {
            setUserHasVoted(true);
            remoteState.addVotes(votes, userId);
          }}
        />
      </Col>

      <Col span={12}>
        {remoteState.currentUser.isModerator && (
          <NextButton
            clickHandler={remoteState.endVoting}
            label="Widerstandabfrage beenden"
          />
        )}
      </Col>
    </Grid>
  );
}

//@ts-ignore
function AddVotesButton({ clickHandler, disabled }) {
  return (
    <Container>
      <Button
        disabled={disabled}
        variant="gradient"
        gradient={{ from: "teal", to: "blue", deg: 60 }}
        size="xl"
        style={{ marginTop: "3em" }}
        onClick={clickHandler}
      >
        Bewertung abgeben
      </Button>
    </Container>
  );
}

//@ts-ignore
function NextButton({ clickHandler, label }) {
  return (
    <Container>
      <Button
        variant="gradient"
        gradient={{ from: "teal", to: "blue", deg: 60 }}
        size="xl"
        style={{ marginTop: "3em" }}
        onClick={() => clickHandler()}
      >
        {label}
      </Button>
    </Container>
  );
}
